import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { env_domain, FIELDSTAT_DEMO_CLIENT_ID, FIELDSTAT_DEMO_CLIENT_SECRET } from 'env';
import { logout, setToken } from './auth';

export const baseUrl = env_domain;

const baseQuery = fetchBaseQuery({
  baseUrl: '/',
  prepareHeaders: (headers, { getState }) => {
    const { token } = getState().auth; // Assuming you store the token in the auth slice
    if (token && !headers.get('Authorization')) {
      headers.set('Authorization', `Bearer ${token}`);
    }
    return headers;
  },
});

const baseQueryWithReauth = async (args, api, extraOptions) => {
  let result = api.getState().auth.token
    ? await baseQuery(args, api, extraOptions)
    : {
        error: {
          status: 401,
        },
      };

  if (result.error && result.error.status === 401) {
    const payload = new URLSearchParams({
      grant_type: 'client_credentials',
      scope: 'api:read',
    });

    // Try to get a new token
    baseQueryWithReauth.queryTokenPromise =
      baseQueryWithReauth.queryTokenPromise ||
      baseQuery(
        {
          url: `${baseUrl}/oauth2/token`,
          method: 'POST',
          body: payload,
          headers: {
            Authorization: `Basic ${btoa(
              `${FIELDSTAT_DEMO_CLIENT_ID}:${FIELDSTAT_DEMO_CLIENT_SECRET}`
            )}`,
          },
        },
        api,
        extraOptions
      );

    const refreshResult = await baseQueryWithReauth.queryTokenPromise;

    if (refreshResult.data) {
      baseQueryWithReauth.queryTokenPromise = null;
      // Store the new token
      api.dispatch(setToken(refreshResult.data.access_token));

      // Retry the original query with the new token
      result = await baseQuery(args, api, extraOptions);
    } else {
      // Handle the case where refresh failed, possibly log out the user
      api.dispatch(logout());
    }
  }

  return result;
};

export default createApi({
  tagTypes: ['farms', 'fields', 'cropstat', 'regionstat'],
  keepUnusedDataFor: 20,
  baseQuery: baseQueryWithReauth,
  refetchOnReconnect: true,
  endpoints: () => ({}),
});
