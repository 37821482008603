import React, { useRef } from 'react';
import useStopWheel from 'hooks/useStopWheel';

const SettingsButton = ({ toggleZoomInfo }) => {
  const scrollRef = useRef(null);
  useStopWheel(scrollRef);

  return (
    <div
      aria-label='button'
      role='button'
      tabIndex={0}
      className='settings-button'
      onClick={toggleZoomInfo}
    >
      <i className='fas fa-cog' />
    </div>
  );
};

export default React.memo(SettingsButton);
