import React, { memo, useMemo } from 'react';
import { lineRegionWrapPaint, lineLayout } from 'utils/map';
import { Layer, Source } from 'react-map-gl';
import { useGetRegionGeometryQuery } from 'store/slices/api/farms';
import { emptyGeojsonFn } from '../constants';

const layerSettings = {
  id: 'Wrapper',
  type: 'line',
  fillLayout: lineLayout,
  paint: {
    ...lineRegionWrapPaint,
  },
};

const SelectedRegionWrapperLayer = ({ selectedRegionId }) => {
  const { data } = useGetRegionGeometryQuery({ regionId: selectedRegionId });
  const selectedRegionGeojson = useMemo(() => {
    if (!data) {
      return null;
    }
    const result = emptyGeojsonFn();
    result.features = [
      {
        type: 'Feature',
        geometry: {
          type: 'LineString',
          coordinates: data.coordinates[0],
        },
      },
    ];
    return result;
  }, [data]);

  if (!selectedRegionGeojson) {
    return null;
  }
  return (
    <Source id='selectedRegionGeojson' type='geojson' data={selectedRegionGeojson}>
      <Layer {...layerSettings} />
    </Source>
  );
};

export default memo(SelectedRegionWrapperLayer);
