/* eslint-disable no-unused-vars */
import React, { useCallback, useEffect, useMemo, useState, useRef } from 'react';
import moment from 'moment';
import classNames from 'classnames';
import { getAreaTitle } from 'utils';
import Select from 'react-select';
import useStopWheel from 'hooks/useStopWheel';
import { useTranslation } from 'react-i18next';
import { copyToClipboard } from 'utils/copyToClipboard';
import { clusterTypes } from '../Map/constants';
import { customStyles } from '../Map/OutletsFilters';

const ZoomInfoField = ({
  zoom,
  center,
  fieldsLength,
  toggleAdditionalLayer,
  toggleRegion,
  toggleShowCrops,
  additionalLayer,
  hoveredPolygonArea,
  statistic,
  isShowCrops,
  regions,
  selectedRegionId,
  isShowCadaster,
  toggleIsShowCadaster,
}) => {
  const { t, i18n } = useTranslation();
  const [isLinkCopied, setIsLinkCopied] = useState(false);
  const scrollRef = useRef(null);
  useStopWheel(scrollRef);

  const timeLoadStatistic = useMemo(
    () => (statistic?.created_at ? moment(statistic.created_at).format('DD-MM-YYYY HH:mm') : null),
    [statistic]
  );

  const currentFieldsLength = useMemo(
    () => (fieldsLength === null ? '-' : fieldsLength),
    [fieldsLength]
  );

  const hoveredPolygonAreaInfo = useMemo(
    () => getAreaTitle(hoveredPolygonArea),
    [hoveredPolygonArea]
  );

  const getShareMapLink = useCallback(() => {
    const searchParams = new URLSearchParams();
    searchParams.append('zoom', zoom.toFixed(1));
    searchParams.append('additionalLayer', additionalLayer);
    searchParams.append('center', center?.replace(' ', ''));
    searchParams.append('lang', i18n.language);
    searchParams.append('region', selectedRegionId);
    searchParams.append('isShowCrops', +isShowCrops);

    copyToClipboard(`${window.location.href}?${searchParams.toString()}`);
    setIsLinkCopied(true);
  }, [zoom, center, additionalLayer, selectedRegionId, isShowCrops]);

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
  };

  const layerOptions = useMemo(
    () => [
      { value: clusterTypes.NONE, label: t('mapSettings.layerSettings.none') },
      { value: clusterTypes.OUTLETS, label: t('mapSettings.layerSettings.dealerOutlets') },
      { value: clusterTypes.SILOS, label: t('mapSettings.layerSettings.grainSilos') },
    ],
    [t]
  );

  const toggleAdditionalLayerCb = useCallback(
    (layer) => {
      toggleAdditionalLayer(layer.value);
    },
    [toggleAdditionalLayer]
  );

  const selectedLayer = useMemo(
    () => layerOptions.find((layer) => layer.value === additionalLayer),
    [layerOptions, additionalLayer]
  );

  const toggleRegionCb = useCallback((region) => toggleRegion(region.value), [toggleRegion]);

  const regionsOptions = useMemo(
    () =>
      [...regions]
        .sort((i1, i2) => i1.sort_order - i2.sort_order)
        .map((region) => ({
          value: region.id,
          label: `${region.country_short_name}, ${region.name}`,
        })),
    [regions]
  );

  const selectedRegion = useMemo(
    () => regionsOptions.find((region) => region.value === selectedRegionId),
    [regionsOptions, selectedRegionId]
  );

  useEffect(() => {
    setIsLinkCopied(false);
  }, [zoom, center]);

  return (
    <div ref={scrollRef} className='zoom-viewer'>
      <table>
        <tbody>
          <tr>
            <td width={140}>{t('mapSettings.zoomLevel')}</td>
            <td width={130}>{zoom.toFixed(1)}</td>
          </tr>
          <tr>
            <td>{t('mapSettings.screenCenter')}</td>
            <td>{center}</td>
          </tr>
          <tr>
            <td>{t('mapSettings.fieldsShown')}</td>
            <td>{currentFieldsLength}</td>
          </tr>
          {/* <tr>
            <td>{t('mapSettings.fieldsTotal')}</td>
            <td>{statistic ? statistic.bounds_count : '-'}</td>
          </tr>
          <tr>
            <td>{t('mapSettings.selectedFieldsArea')}</td>
            <td>
              {hoveredPolygonAreaInfo} {hoveredPolygonAreaInfo !== '-' ? t('ac') : ''}
            </td>
          </tr>
          <tr>
            <td>{t('mapSettings.updated')}</td>
            <td>{statistic ? timeLoadStatistic : '-'}</td>
          </tr> */}
          {/* <tr>
            <td>{t('mapSettings.layer')}</td>
            <td>
              <Select
                value={selectedLayer}
                name='kind'
                options={layerOptions}
                className='filters__item'
                onChange={toggleAdditionalLayerCb}
                styles={customStyles}
              />
            </td>
          </tr> */}
          <tr>
            <td>{t('mapSettings.region')}</td>
            <td>
              <Select
                value={selectedRegion}
                name='kind'
                options={regionsOptions}
                className='filters__item'
                onChange={toggleRegionCb}
                styles={customStyles}
                menuPortalTarget={document.body}
              />
            </td>
          </tr>
          <tr>
            <td>{t('mapSettings.language')}</td>
            <td>
              <span
                onClick={() => changeLanguage('en')}
                className={i18n.language !== 'en' ? 'zoom-viewer__btn-link' : ''}
                role='button'
                tabIndex={0}
              >
                en
              </span>
              <span> / </span>
              <span
                onClick={() => changeLanguage('ru')}
                className={i18n.language !== 'ru' ? 'zoom-viewer__btn-link' : ''}
                role='button'
                tabIndex={0}
              >
                ru
              </span>
            </td>
          </tr>
          <tr>
            <td>
              <label htmlFor='isShowCrops'>{t('mapSettings.showCrops')}</label>
            </td>
            <td>
              <input
                type='checkbox'
                id='isShowCrops'
                defaultChecked={!!isShowCrops}
                onChange={toggleShowCrops}
              />
            </td>
          </tr>
          <tr>
            <td>
              <label htmlFor='isShowCadaster'>{t('mapSettings.ruCadaster')}</label>
            </td>
            <td>
              <input
                type='checkbox'
                id='isShowCadaster'
                defaultChecked={!!isShowCadaster}
                onChange={toggleIsShowCadaster}
              />
            </td>
          </tr>
        </tbody>
      </table>
      <div
        className={classNames('copy-link', isLinkCopied ? 'disabled' : '')}
        tabIndex={0}
        role='button'
        onClick={getShareMapLink}
      >
        {isLinkCopied ? t('mapSettings.linkCopied') : t('mapSettings.copyMapLink')}
      </div>
    </div>
  );
};

export default React.memo(ZoomInfoField);
