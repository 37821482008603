import { useEffect } from 'react';
import { useLazyGetRegionsQuery } from 'store/slices/api/farms';
import { actions } from '../mapReducer';

const useInitRegions = ({ dispatch, selectedRegionId }) => {
  const [triggerLoadRegions] = useLazyGetRegionsQuery();

  const initRegions = async () => {
    let resultSelectedRegionId = selectedRegionId || localStorage.getItem('selectedRegionId');
    const response = await triggerLoadRegions();
    const data = response?.data?.data || [];
    dispatch({ type: actions.SET_REGIONS, payload: data });
    if (!data.find((region) => region.id === +resultSelectedRegionId)) {
      resultSelectedRegionId = data?.[0]?.id || null;
      dispatch({
        type: actions.SET_REGION_ID,
        payload: resultSelectedRegionId,
      });
    }
    return resultSelectedRegionId;
  };

  useEffect(() => {
    initRegions();
  }, []);
};

export default useInitRegions;
