/* eslint-disable no-param-reassign */
/* eslint-disable no-shadow */
import React, { memo, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Table } from 'antd';
import { Link, useParams } from 'react-router-dom';
import { useGetRegionStatsQuery, useLazyGetFarmsByRegionQuery } from 'store/slices/api/farms';
import { useTranslation } from 'react-i18next';
import { convertMeters } from 'utils/convertMeters';

const TableComponent = () => {
  const params = useParams();
  const [ref, setRef] = useState();
  const { t, i18n } = useTranslation();
  const isLoaded = useRef(false);
  const [isLoading, setIsLoading] = useState(true);
  const currentCursor = useRef('firstMock');
  const [data, setData] = useState({
    data: [],
    pagination: {},
  });
  const [handleLoad] = useLazyGetFarmsByRegionQuery();

  const { isLoading: isLoadingStats, data: dataStats } = useGetRegionStatsQuery({
    regionId: params.id,
  });

  const popularCulturesNames = useMemo(() => {
    if (dataStats?.crops_stats) {
      return [...(dataStats?.crops_stats || [])]
        .sort((a, b) => b.fields_area - a.fields_area)
        .reduce((result, { crop_specific, crop_class }) => {
          if (result.length < 6) {
            return result.concat(crop_specific || crop_class);
          }
          return result;
        }, []);
    }
    return [];
  }, [dataStats]);

  const loadItems = useCallback(async (cursor) => {
    const { data: newData } = await handleLoad({ regionId: params.id, cursor });
    currentCursor.current = newData?.pagination?.cursor;
    setData((data) => {
      if (data.pagination.cursor === newData.pagination.cursor) {
        return data;
      }
      return {
        data: [...data.data].concat([...newData.data]),
        pagination: newData.pagination,
      };
    });
  }, []);

  useEffect(() => {
    if (isLoaded.current) {
      return;
    }
    isLoaded.current = true;
    loadItems();
  }, []);

  useEffect(() => {
    if (currentCursor.current === 'firstMock') {
      return;
    }

    if (currentCursor.current) {
      setIsLoading(true);
      loadItems(currentCursor.current);
    } else {
      setIsLoading(false);
    }
  }, [data]);

  const columns = useMemo(
    () => [
      {
        dataIndex: 'name',
        key: 'name',
        sorter: (a, b) => (a.name > b.name ? -1 : 1),
        width: '28%',
        title: () => <div className='farm-page__title-inactive'>{t('farmTableColumns.farm')}</div>,
        render: (text, object) => (
          <Link
            className='farm-page__link'
            to={`/farms/${localStorage.getItem('selectedRegionId') || 1}/${object?.id || 0}`}
          >
            {text}
            {object.tax_id ? ` (${object.tax_id})` : ''}
          </Link>
        ),
      },
      {
        dataIndex: 'fields_area',
        defaultSortOrder: 'ascend',
        title: (info) => (
          <div
            className={
              info?.sortColumns?.[0]?.column?.key === 'area' && info?.sortColumns?.[0]?.order
                ? ''
                : 'farm-page__title-inactive'
            }
          >
            {t('farmTableColumns.totalCropland')}, {t('ha')}
          </div>
        ),
        sorter: (a, b) => (a.fields_area > b.fields_area ? -1 : 1),
        key: 'area',
        render: (text) => (
          <div className='farm-page__number'>{text ? convertMeters(text) : '0'}</div>
        ),
      },
      ...popularCulturesNames.map((key) => ({
        dataIndex: key,
        sorter: (a, b) => ((a[key] || 0) > (b[key] || 0) ? -1 : 1),
        title: (info) => (
          <div
            className={
              info?.sortColumns?.[0]?.column?.key === key && info?.sortColumns?.[0]?.order
                ? ''
                : 'farm-page__title-inactive'
            }
          >
            {t(`farmTableColumns.${`${key}`}`, key)}, {t('ha')}
          </div>
        ),
        key,
        render: (text) => (
          <div className='farm-page__number'>{text ? convertMeters(text) : '0'}</div>
        ),
      })),
    ],
    [i18n.language, popularCulturesNames]
  );

  return (
    <div className='farm-page' ref={setRef}>
      <Table
        loading={isLoading || isLoadingStats}
        dataSource={isLoading ? [] : data?.data || []}
        columns={columns}
        pagination={false}
        scroll={{ y: window.innerHeight - (ref?.offsetTop || 0) - 110 }}
      />
    </div>
  );
};

export default memo(TableComponent);
