import { useEffect, useCallback } from 'react';
import { MAPBOX_TOKEN } from 'env';
import { actions } from '../mapReducer';

const token = MAPBOX_TOKEN;

const useGetToken = ({ dispatch }) => {
  const getToken = useCallback(async () => {
    try {
      dispatch({ type: actions.SET_TOKEN, payload: token });
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error('Ошибка получения token карты');
    }
  }, []);

  useEffect(() => {
    getToken();
  }, []);
};

export default useGetToken;
