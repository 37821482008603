/* eslint-disable no-unused-vars */
import React, { Suspense, useCallback, useState } from 'react';
import Header from 'views/Map/components/Header';
import HeaderLoader from 'views/Map/components/HeaderLoader';
import { useParams } from 'react-router-dom';
import OwnerInfo from './components/OwnerInfo';
import Map from './components/Map';

const FarmItem = () => {
  const params = useParams();
  const [isShowAdditionalFields, setIsShowAdditionalFields] = useState(false);

  const toggleFields = useCallback(() => {
    setIsShowAdditionalFields((isShow) => !isShow);
  }, []);

  return (
    <div className='main-page'>
      <Suspense fallback={<HeaderLoader />}>
        <Header />
      </Suspense>
      <div className='farm-item__wrap'>
        <div className='farm-item__map'>
          <Suspense fallback={<></>}>
            <Map key={params.owner} isShowAdditionalFields={isShowAdditionalFields} />
          </Suspense>
        </div>

        <div className='farm-item__info'>
          <Suspense fallback={<></>}>
            <OwnerInfo
              toggleFields={toggleFields}
              isShowAdditionalFields={isShowAdditionalFields}
            />
          </Suspense>
        </div>
      </div>
    </div>
  );
};

export default FarmItem;
