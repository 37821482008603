import { env_domain } from 'env';

export const baseUrl = env_domain;

export const urls = () =>
  // urls object is caching after a module import so there is no way to change customerId later on,
  // so we need to wrap it as a function to get appropriate customerId value on every urls call

  ({
    // System-wide
    statistic: `${baseUrl}/stat`,
    estimate: `${baseUrl}/estimate`,
    farm: `${baseUrl}/api/regions/{region_id}/farms/{farm_id}`,
    count: `${baseUrl}/count`,
    bounds: `${baseUrl}/bounds`,
    outlets: `${baseUrl}/outlets`,
    farms: `${baseUrl}/api/regions/{region_id}/farms`,
    regionsList: `${baseUrl}/api/regions`,
    regionGeometry: `${baseUrl}/api/regions/{region_id}/geometry`,
    regionsStats: `${baseUrl}/api/regions/{region_id}/analytics/crops`,
    silos: `${baseUrl}/silos`,
    mapboxToken: `${baseUrl}/mapbox-token`,
    owners: `${baseUrl}/regions/{region_id}/owners`,
    cropstat: `${baseUrl}/regions/{region_id}/cropstat`,
    fields: `${baseUrl}/api/fields`,
    ownersBounds: `${baseUrl}/api/regions/{region_id}/farms/{farm_id}/fields`,
  });
