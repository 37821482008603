/* eslint-disable no-unused-vars */
import React, { Suspense, useCallback, useMemo, useRef } from 'react';
import { Map as MapItem, NavigationControl } from 'react-map-gl';
import mapboxSettings from 'mapboxSettings';
import debounce from 'lodash.debounce';
import { getBBoxFromMap, getBounds, getEstimate, getCount } from 'utils/map';
import { getCurrentPolygon, getAreaByPolygon, getAreaTitle } from 'utils';

import useStopWheel from 'hooks/useStopWheel';
import { useLazyGetFieldsQuery } from 'store/slices/api/farms';
import OutletsMarkersCluster from './OutletsMarkersCluster';
import OutletsFilters from './OutletsFilters';
import SilosFilters from './SilosFilter';
import { actions } from './mapReducer';
import { clusterTypes, boundsMinZoom, maxPolygonsOnMap } from './constants';

import useLoadFilters from './cutomHooks/useLoadFilters';
import useInitRegions from './cutomHooks/useInitRegions';
import useGetToken from './cutomHooks/useGetToken';
import PopupField from './PopupField';
import SettingsButton from './SettingsButton';
import MapSpinner from './MapSpinner';
import ZoomButton from './ZoomButton';
import CadasterNumberModal from './CadasterNumberModal';
import useSetDefaultSearchParams from './cutomHooks/useSetDefaultSearchParams';
import ZoomInfoField from '../ZoomInfoField';
import { useTogleRosreestr } from './cutomHooks/useTogleRosreestr';
import SelectedRegionWrapperLayer from './SelectedRegionWrapperLayer';
import PolygonLines from './PolygonLines';
import WrapPolygons from './WrapPolygons';
import FieldsPolygons from './FieldsPolygons';
import SilosMarkerCluster from './SilosMarkerCluster';

const Map = ({ dispatch, state }) => {
  const mapLink = useRef();
  const scrollRef = useRef(null);
  const cogRef = useRef(null);
  useStopWheel(scrollRef);
  useStopWheel(cogRef);

  const isLoadedSettings = useSetDefaultSearchParams({ dispatch });
  const { contextHolder } = useTogleRosreestr({
    map: mapLink.current,
    isShowCadaster: state.isShowCadaster,
    lngLat: state.lngLat,
    dispatch,
  });
  const [refetchAllFields] = useLazyGetFieldsQuery();

  const { silos, outlets } = {};

  // const { silos, outlets } = useLoadFilters({
  //   dispatch,
  //   clusterType: state.clusterType,
  //   silos: state.silos,
  //   filters: state.filters,
  //   outlets: state.outlets,
  // });
  useGetToken({ dispatch });
  useInitRegions({ dispatch, selectedRegionId: state.selectedRegionId });

  const resetPopups = useCallback(() => {
    dispatch({ type: actions.RESET_POPUPS });
  }, []);

  const onMove = useCallback(() => {
    resetPopups();
  }, [resetPopups]);

  const onChangeFilter = useCallback(
    (value, { name }) => {
      dispatch({ type: actions.ADD_FILTERS, payload: { [name]: value } });
      resetPopups();
    },
    [resetPopups]
  );

  const toggleShowCrops = useCallback((payload) => {
    dispatch({ type: actions.TOGGLE_SHOW_CROPS, payload });
  }, []);

  const toggleIsShowCadaster = useCallback((payload) => {
    dispatch({ type: actions.TOGGLE_IS_SHOW_CADASTER, payload });
  }, []);

  const hideCN = useCallback(() => {
    dispatch({ type: actions.TOGGLE_IS_SHOW_CN, payload: '' });
  }, []);

  const toggleAdditionalLayer = useCallback((payload) => {
    resetPopups();
    dispatch({ type: actions.SET_FILTERS, payload: {} });
    dispatch({ type: actions.TOGGLE_VIEW, payload });
  }, []);

  const toggleRegion = useCallback((payload) => {
    dispatch({ type: actions.SET_START_PARAMS, payload: null });
    dispatch({ type: actions.SET_REGION_ID, payload });
  }, []);

  const loadEstimate = useCallback(async (currentZoom, params, bbox) => {
    if (currentZoom > boundsMinZoom) {
      dispatch({ type: actions.SET_WRAP_POLYGON, payload: [] });
      return null;
    }
    const estimateResponse = await getEstimate(params);

    const { hull: currentWrapPolygon, count: currentPolygonsLength } = estimateResponse.data;
    currentWrapPolygon.key = `${currentZoom}${bbox}`;
    dispatch({ type: actions.SET_WRAP_POLYGON, payload: [currentWrapPolygon] });

    return currentPolygonsLength;
  }, []);

  const loadBounds = useCallback(async (currentZoom, bbox) => {
    if (currentZoom < boundsMinZoom) {
      dispatch({ type: actions.SET_POLYGONS, payload: [] });
      return 0;
    }

    const params = {
      bbox,
    };
    let resultPolygons = [];
    let cursor = null;
    const boundsResponse = await refetchAllFields(params);
    const { pagination = {}, data = [] } = boundsResponse?.data || {};
    resultPolygons = resultPolygons.concat(data);
    cursor = pagination.cursor;

    if (cursor) {
      while (cursor && resultPolygons.length < maxPolygonsOnMap) {
        const nextResponse = await refetchAllFields({ ...params, cursor });
        cursor = nextResponse.data?.pagination?.cursor;
        resultPolygons = (resultPolygons || []).concat(nextResponse.data?.data || []);
      }
    }

    const currentPolygons = (resultPolygons || []).map((item, index) => ({
      ...item.geometry,
      key: `${currentZoom}${bbox}${index}`,
      field_id: `${currentZoom}${bbox}${index}`,
      properties: {
        ...item,
        cropClass: item.crops?.find((crop) => crop.season == item.processed_season)?.crop_class,
        cropSpecific: item.crops?.find((crop) => crop.season == item.processed_season)
          ?.crop_specific,
      },
    }));
    dispatch({ type: actions.SET_POLYGONS, payload: currentPolygons });
    return currentPolygons.length;
  }, []);

  const scrollToLoadedZoom = useCallback(() => {
    if (mapLink.current) {
      mapLink.current.flyTo({ zoom: boundsMinZoom + 0.1, essential: true });
    }
  }, []);

  const loadPolygons = useCallback(
    async (map) => {
      try {
        mapLink.current = map;
        dispatch({ type: actions.START_LOADING });

        const currentZoom = map.getZoom();
        const currentCenter = map.getCenter();
        dispatch({
          type: actions.SET_MAP_INFO,
          payload: {
            zoom: currentZoom,
            mapCenter: currentCenter,
          },
        });

        const bbox = getBBoxFromMap(map);
        const count = await loadBounds(currentZoom, bbox);

        dispatch({
          type: actions.FINISH_LOADING,
          payload: {
            allPolygonsLength: count,
          },
        });
      } catch (e) {
        dispatch({ type: actions.CLEAR_ON_ERROR });
        // eslint-disable-next-line no-console
        console.error('Ошибка получения полигонов: ', e);
      }
    },
    [loadBounds]
  );

  const onHoverPolygon = useCallback(
    debounce((payload) => {
      dispatch({ type: actions.SET_HOVERED_POLYGON, payload });
    }, 500),
    []
  );

  const onStyleLoad = useCallback(
    (event) => {
      const map = event.target;
      const cb = debounce(() => loadPolygons(map), 600);
      map.on('zoom', cb);
      map.on('move', cb);
      map.on('mousemove', 'Fields-fill', (e) => {
        map.setFilter('FieldsHover-fill', ['==', 'field_id', e.features[0].properties.field_id]);
        onHoverPolygon(e.features[0].properties.field_id);
      });
      map.on('mouseleave', 'Fields-fill', () => {
        map.setFilter('FieldsHover-fill', ['==', 'field_id', 0]);
        onHoverPolygon(null);
      });
      loadPolygons(map);
    },
    [loadPolygons]
  );

  const toggleZoomInfo = useCallback(() => {
    dispatch({ type: actions.TOGGLE_IS_SHOW_ZOOM_PANEL });
  }, []);

  const fillOnClick = useCallback(
    (e) => {
      const features = e.target.queryRenderedFeatures(e.point, {
        layers: ['Fields-fill'],
      });

      resetPopups();
      const feature = features?.[0];

      if (!feature) {
        return;
      }

      const { properties } = feature || {};
      const { field_id, cropClass, cropSpecific, farm, cadastral } = properties || {};

      const farmParsed = JSON.parse(farm || '{}');
      const cadastralParsed = JSON.parse(cadastral || '{}');

      dispatch({
        type: actions.SET_POPUP_FIELD,
        payload: {
          lat: e.lngLat?.lat,
          lon: e.lngLat?.lng,
          area: getAreaTitle(getAreaByPolygon(getCurrentPolygon(state.polygons, field_id)), 0),
          areaHa: getAreaTitle(
            getAreaByPolygon(getCurrentPolygon(state.polygons, field_id)),
            0,
            'ha'
          ),
          crop: cropClass,
          crop_original: cropSpecific,
          owner: `${farmParsed?.name || ''}${farmParsed.tax_id ? ` (${farmParsed.tax_id})` : ''}`,
          farmId: farmParsed?.id,
          cadastral_status: cadastralParsed?.status,
          cadastral_number: cadastralParsed?.number,
        },
      });
    },
    [state.polygons, resetPopups]
  );

  const onOpenPopupMarker = useCallback(
    (value) => {
      resetPopups();
      dispatch({ type: actions.SET_POPUP_MARKER, payload: value });
    },
    [resetPopups]
  );

  const mapBounds = useMemo(() => {
    const box = state.regions?.find((region) => region.id == state.selectedRegionId)?.bbox;
    return box?.length
      ? [
          [box[0], box[1]],
          [box[2], box[3]],
        ]
      : undefined;
  }, [state.regions, state.selectedRegionId]);

  const outletsOnClick = useCallback(
    (e) => {
      const features = e.target.queryRenderedFeatures(e.point, {
        layers: [
          state.clusterType === clusterTypes.OUTLETS
            ? 'unclusteredOutletPoint'
            : 'unclusteredSilosPoint',
        ],
      });

      const feature = features?.[0];

      if (!feature) {
        return;
      }

      onOpenPopupMarker(JSON.parse(feature.properties.strObj));
    },
    [state.clusterType]
  );

  const onClickMap = useCallback(
    (e) => {
      dispatch({ type: actions.SET_LNG_LAT_CLICK, payload: e.lngLat });
      fillOnClick(e);
    },
    [fillOnClick]
  );

  const initialViewState = useMemo(
    () => ({
      ...(mapBounds && !state.startParams ? { bounds: mapBounds } : {}),
      zoom: state.startParams?.zoom || mapboxSettings.zoom?.[0],
      ...(state.startParams?.center || !mapBounds
        ? {
            longitude: state.startParams?.center?.lng || 62.5,
            latitude: state.startParams?.center?.lat || 62.5,
          }
        : {}),
    }),
    [mapBounds, state.startParams]
  );

  if (
    !state.token ||
    !(initialViewState.bounds || initialViewState.longitude) ||
    !isLoadedSettings ||
    !state.selectedRegionId ||
    !state.regions?.length
  ) {
    return <></>;
  }

  return (
    <div className='map-container'>
      {state.isPolygonsLoading && <MapSpinner />}
      {!!state.silos?.length && state.clusterType === clusterTypes.SILOS && (
        <SilosFilters
          className='map-container__filters'
          silos={state.silos}
          filters={state.filters}
          onChangeFilter={onChangeFilter}
          onMenuOpen={resetPopups}
        />
      )}
      {!!state.outlets?.length && state.clusterType === clusterTypes.OUTLETS && (
        <OutletsFilters
          className='map-container__filters'
          outlets={state.outlets}
          filters={state.filters}
          onChangeFilter={onChangeFilter}
          onMenuOpen={resetPopups}
        />
      )}

      <MapItem
        // eslint-disable-next-line import/no-webpack-loader-syntax
        mapLib={import('!mapbox-gl')}
        key={initialViewState.bounds}
        mapStyle='mapbox://styles/theirix/ckh4x4ekq03bq19odb14zifzz'
        mapboxAccessToken={state.token}
        className='map-container__field-map'
        initialViewState={initialViewState}
        interactiveLayerIds={[]}
        onLoad={onStyleLoad}
        onMove={onMove}
        onClick={onClickMap}
      >
        <WrapPolygons wrapPolygon={state.wrapPolygon} />

        <FieldsPolygons isShowCrops={state.isShowCrops} polygons={state.polygons} />

        <SelectedRegionWrapperLayer
          selectedRegionId={state.selectedRegionId}
          regions={state.regions}
        />

        <PolygonLines polygons={state.polygons} />

        {state.clusterType === clusterTypes.OUTLETS && (
          <OutletsMarkersCluster outlets={outlets} popup={state.popupMarker} />
        )}

        {state.clusterType === clusterTypes.SILOS && (
          <SilosMarkerCluster silos={silos} popup={state.popupMarker} />
        )}

        <div ref={scrollRef} className='zoom-bounds'>
          <NavigationControl showCompass={false} />
        </div>
        <Suspense fallback={<></>}>
          <ZoomButton zoom={state.zoom} scrollToLoadedZoom={scrollToLoadedZoom} />
        </Suspense>
        <div ref={cogRef} className='settings'>
          <Suspense fallback={<></>}>
            <SettingsButton toggleZoomInfo={toggleZoomInfo} dispatch={dispatch} />
          </Suspense>
          {state.isShowZoomInfo && (
            <ZoomInfoField
              zoom={state.zoom || 0}
              center={state.mapCenter}
              fieldsLength={state.allPolygonsLength}
              isShowCrops={state.isShowCrops}
              hoveredPolygonArea={state.hoveredPolygonArea}
              statistic={state.statistic}
              regions={state.regions}
              toggleRegion={toggleRegion}
              toggleShowCrops={toggleShowCrops}
              selectedRegionId={state.selectedRegionId}
              toggleAdditionalLayer={toggleAdditionalLayer}
              additionalLayer={state.clusterType}
              isShowCadaster={state.isShowCadaster}
              toggleIsShowCadaster={toggleIsShowCadaster}
            />
          )}
        </div>
        <CadasterNumberModal cn={state.cn} isShow={!!state.cn} onClose={hideCN} />
        {!!state.popupField && !state.isShowCadaster && (
          <PopupField popupField={state.popupField} />
        )}
      </MapItem>
      {contextHolder}
    </div>
  );
};

export default React.memo(Map);
